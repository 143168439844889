
<section class="vh-100">
    <div class="col-md-8 col-lg-7 col-xl-6">
      <img src="http://icm.imgateway.net/assets/img/imgateway_logo.png"
        class="img-fluid" alt="Phone image">
    </div>
    
    <h1 class="display-4" style="padding-left: 506px;"><b>{{platformTitle}} Tool</b></h1>
    <div class="container py-5 h-100">
      <div class="row d-flex align-items-center justify-content-center h-100">
        <div class="col-md-8 col-lg-7 col-xl-6">
          <img src="https://www.imgateway.net/images2/theme-productDirect.png"
            class="img-fluid" alt="Phone image">
        </div>
        <div class="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
          <form (submit)="onSubmit()">
            <!-- Email input -->
            <div class="form-outline mb-4">
              <label for="exampleInputEmail1" class="form-label"><b>Username:</b></label>
              <input type="email" class="form-control form-control-lg" name="email" [(ngModel)]="username">
            
            </div>
  
            <!-- Password input -->
            <div class="form-outline mb-4">
              <label for="exampleInputPassword1" class="form-label "><b>Password:</b></label>
              <input type="password" class="form-control" name="password" [(ngModel)]="password">
              
            </div>
  
           
            <!-- Submit button -->
           
            <button type="submit" class="btn btn-primary btn-lg btn-block"  >Sign In</button>
           
  
          </form>
        </div>
      </div>
    </div>
  </section>