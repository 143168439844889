import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { AdminUser } from '../model/adminUser';

const baseUrl = environment.baseURL + '/api/admin';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { 

  }

  login(username: string,password:string): Observable<AdminUser>{

    let adminUser :AdminUser=new AdminUser;

    adminUser.username=username;
    adminUser.password=password;
    console.log()
    return this.http.post<any>(baseUrl + '/login',adminUser)
    .pipe(
      tap( _ => console.log('drug item get value') ),
      catchError( err => of ([]) )
    );

  }

}
