<div class="container rounded shadow p-4 mb-4 bg-white">

<br/>

<h4 class="">Welcome to {{platformTitle}} Tool</h4>


<p>This tool allows you to add,update and delete the following:</p>

<br/>


<div class="d-flex justify-content-center flex-wrap">
    <div class="card col-md-2 bg-dark text-light "  [routerLink]="['/herbslist']">Ingredients</div>
    <div class="card col-md-2 bg-dark text-light " [routerLink]="['/herbspreplist']">Brand/Preparation</div>
    <div class="card col-md-2 bg-dark text-light " [routerLink]="['/drugslist']" >Drugs</div>
    <div class="card col-md-2 bg-dark text-light " [routerLink]="['/tcislist']" >TCIs</div>
</div>

<br/>

<div class="d-flex justify-content-center flex-wrap">
    <div class="card col-md-2 bg-dark text-light " [routerLink]="['/interactionslist']">Interactions</div>
    <div class="card col-md-2 bg-dark text-light " [routerLink]="['/generalinteractionslist']">General Interactions</div>
    <div class="card col-md-2 bg-dark text-light " [routerLink]="['/reportslist']">Reports</div>
    <div class="card col-md-2 bg-dark text-light " [routerLink]="['/referenceslist']">References</div>
</div>


</div>




