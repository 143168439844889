export const environment = {
  platformTitle:'ICM Interactions Admin',
//  platformTitle:'Interactions Maintenance',
  production: true,
  // baseURL: 'http://test.ingredients.imgateway.net'
  //baseURL: 'http://testexample.interactions.imgateway.net'
 // baseURL: 'https://interactions.imgateway.net'
  // baseURL: 'http://localhost:10156'
  baseURL: ''

};
