import { Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";

export const AppRoutes: Routes = [

  {
    path: '', component: LoginComponent
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'herbslist',
    loadChildren: () => import('./herbs-list/herbs-list.module').then(m => m.HerbsListModule)
  },
  {
    path: 'herbadd',
    loadChildren: () => import('./herbs-add/herbs-add.module').then(m => m.HerbsAddModule)
  },
  {
    path: 'herbspreplist',
    loadChildren: () => import('./herbs-prep-list/herbs-prep-list.module').then(m => m.HerbsPrepListModule)
  },
  {
    path: 'herbprepadd',
    loadChildren: () => import('./herbs-prep-add/herbs-prep-add.module').then(m => m.HerbsPrepAddModule)
  },
  {
    path: 'drugslist',
    loadChildren: () => import('./drugs-list/drugs-list.module').then(m => m.DrugsListModule)
  },
  {
    path: 'drugadd',
    loadChildren: () => import('./drugs-add/drugs-add.module').then(m => m.DrugsAddModule)
  },
  {
    path: 'tcislist',
    loadChildren: () => import('./tci-list/tci-list.module').then(m => m.TciListModule)
  },
  {
    path: 'tciadd',
    loadChildren: () => import('./tci-add/tci-add.module').then(m => m.TciAddModule)
  },
  {
    path: 'referenceslist',
    loadChildren: () => import('./references-list/references-list.module').then(m => m.ReferencesListModule)
  },
  {
    path: 'referenceadd',
    loadChildren: () => import('./references-add/references-add.module').then(m => m.ReferencesAddModule)
  },
  {
    path: 'reportslist',
    loadChildren: () => import('./reports-list/reports-list.module').then(m => m.ReportsListModule)
  },
  {
    path: 'reportadd',
    loadChildren: () => import('./reports-add/reports-add.module').then(m => m.ReportsAddModule)
  },
  {
    path: 'interactionslist',
    loadChildren: () => import('./interactions-list/interactions-list.module').then(m => m.InteractionsListModule)
  },
  {
    path: 'interactionadd',
    loadChildren: () => import('./interactions-add/interactions-add.module').then(m => m.InteractionsAddModule)
  },
  {
    path: 'generalinteractionslist',
    loadChildren: () => import('./general-interactions-list/general-interactions-list.module').then(m => m.GeneralInteractionsListModule)
  },
  {
    path: 'generalinteractionadd',
    loadChildren: () => import('./general-interactions-add/general-interactions-add.module').then(m => m.GeneralInteractionsAddModule)
  }

]
