import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  platformTitle = environment.platformTitle;
  constructor(private router:Router) { }

  ngOnInit(): void {

    let userId=localStorage.getItem("user_id");
    if(userId ==null || userId =="null"){
      this.router.navigateByUrl("");
    }
    
  }

}
