<nav class="navbar navbar-expand-sm bg-dark navbar-dark  "  *ngIf="showNavBar()">  

    <a class="navbar-brand" [routerLink]="['/home']"> {{platformTitle}} </a> 

    <ul class="navbar-nav  ">
        <!-- Home -->
        <li class=" nav-item dropdown" dropdown >
            <a class="nav-link dropdown-toggle" id="b1"  data-toggle="dropdown" aria-controls="navbardrop"  dropdownToggle  >Manage Ingredients</a> 
            <div class="dropdown-menu" *dropdownMenu  id="navbardrop" aria-labelledby="b1">
                <a class="dropdown-item" [routerLink]="['/herbslist']" >Ingredients</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" [routerLink]="['/herbspreplist']" >Brand/Preparation</a>
            </div>
        </li>
        <li class=" nav-item dropdown" dropdown >
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" dropdownToggle >Manage Drugs</a> 
            <div class="dropdown-menu" *dropdownMenu >
                <a class="dropdown-item" [routerLink]="['/drugslist']" >Drugs</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" [routerLink]="['/tcislist']" >TCIs</a>
            </div>
        </li>
        <li class=" nav-item dropdown" dropdown >
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" dropdownToggle >Manage Interactions</a> 
            <div class="dropdown-menu" *dropdownMenu >
                <a class="dropdown-item" [routerLink]="['/interactionslist']" >Interactions</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" [routerLink]="['/generalinteractionslist']" >General Interactions</a>
            </div>
        </li>
        <li class=" nav-item dropdown" dropdown >
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" dropdownToggle >Manage Report</a> 
            <div class="dropdown-menu" *dropdownMenu >
                <a class="dropdown-item" [routerLink]="['reportslist']" >Reports</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" [routerLink]="['referenceslist']" >References</a>
            </div>
        </li>
        <li class=" nav-item "  >
            <a class="nav-link "  (click)="logout()" style="cursor: pointer">Log out</a> 
            
        </li>
  
    </ul>
   
    </nav>
     