import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
   username:string="";
   userId:string="";
   platformTitle = environment.platformTitle;

  constructor(private router:Router) { }

  ngOnInit(): void {
   

    this.username=localStorage.getItem("username");
    this.userId=localStorage.getItem("user_id");


let userId=localStorage.getItem("user_id");
// if(userId ==null || userId =="null" || userId ==undefined){
//   this.router.navigateByUrl("");
// }
  }

  showNavBar(){
    this.userId=localStorage.getItem("user_id");
//console.log(this.userId);
    if(this.userId ==null){
      return false;
    }else if(this.userId == "null"){
      return false;
    }else if(this.userId == undefined){
      return false;
    }else{
      return true;
    }
  }

logout(){
  localStorage.setItem("username",null);
  localStorage.setItem("user_id",null);
  this.username=null;
  this.userId=null;
  this.router.navigateByUrl("/");
}

}
