import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../shared/service/login.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  platformTitle = environment.platformTitle;
  username: string = "";
  password: string = "";

  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit(): void {

  }

  onSubmit() {
    // alert(this.username + this.password);

    this.loginService.login(this.username, this.password).subscribe(resp => {
      let reponse: any = resp;
      console.log(resp);
      if (reponse.id != null && reponse.id != 0) {
        localStorage.setItem("username", reponse.username);
        localStorage.setItem("user_id", reponse.id);

        this.router.navigateByUrl("/home");
      } else {
        alert("Login Failed");
      }

    });
  }
}
